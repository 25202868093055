import { graphql } from 'gatsby'
import React from 'react'

import { AboutPageType } from '../../@import-types/contentful/AboutPage.types'
import ContentArea from '../../components/ContentArea'
import Layout from '../../components/Layout'
import VideoHero from '../../components/_heroes/VideoHero'
import Colors from '../../constants/Colors'
import { RootPageQuery } from '../../graphql/global.graphql'

type AboutPageProps = RootPageQuery<{
  contentfulAboutPage: AboutPageType
}>

export default function AboutPage({ data, pageContext }: AboutPageProps) {
  const { contentfulAboutPage } = data

  const { title, heroText, heroImage, content, heroPoster } =
    contentfulAboutPage

  return (
    <Layout
      data={data}
      basePageContent={contentfulAboutPage}
      pageContext={pageContext}
      pageBackgroundColor={Colors.GreenDark}
      pageTextColor={Colors.White}
    >
      <VideoHero
        title={title}
        description={heroText}
        media={heroImage}
        poster={heroPoster}
      />

      {content && <ContentArea content={content} />}
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPageById($id: String!, $startPageId: String!, $locale: String!) {
    contentfulAboutPage(id: { eq: $id }) {
      ...BasePageFragment

      title

      heroImage {
        title
        description
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          contentType
          url
        }
      }

      heroPoster {
        title
        description
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      heroText

      content {
        __typename

        ... on Node {
          ...ContentAreaFragment
        }
      }
    }

    ...GlobalFragment
  }
`
